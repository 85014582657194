.main {
	background-color: #111111;
	padding-top: 10px;
	padding-right: 30px;
	padding-bottom: 30px;
	padding-left: 30px;
}

.main-body {
	background-color: #212121;
}

.navBar {
	background-color: #111111;
}

.background-black {
	background-color: #000000;
}

body {
	font-family: 'Courier New', sans-serif;
	background-color: #484848;
	padding-top: 30px;
	padding-right: 30px;
	padding-bottom: 30px;
	padding-left: 30px;
}

/* Basic */
a {
	color: #3CF;
    text-decoration: none;
}

a:hover {
	color: #FC3;
    text-decoration: none;
}

ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
}

p {
    font-size: 13px;
    line-height: 22px;
}

